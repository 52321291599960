import { useState, ButtonHTMLAttributes, useCallback } from 'react';
import { GraphingCalculator } from 'desmos-react';
import NavButton from './Navigation/NavButton.tsx';
import { Calc } from './Icons.tsx';
import Popover from './Popover.tsx';

const CalculatorButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>();
  const click = useCallback(() => setOpen(x => !x), []);
  const close = useCallback(() => setOpen(false), []);

  return (
    <>
      <NavButton {...props} ref={setAnchor} Icon={Calc} onClick={click}>calc</NavButton>
      <CalculatorDialog
        open={open}
        anchor={anchor}
        onClose={close}
      />
    </>
  );
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  anchor?: Element | null;
}

const CalculatorDialog = ({ open, anchor, onClose }: DialogProps) => (
  <Popover
    draggable
    open={open}
    title="Calculator"
    anchor={anchor}
    placement="bottom-end"
    onClose={onClose}
    width="50rem"
    unmount={false}
    disableAwayClick
  >
    <GraphingCalculator
      keypad={true}
      autosize
      folders={false}
      images={false}
      notes={false}
      links={false}
      attributes={{ className: 'calculator', style: { height: '70vh', maxHeight: '40rem' } }}
    />
  </Popover>
);

export default CalculatorButton;
