import { useCallback } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { ConfigProp, DataProp } from 'editorjs-blocks-react-renderer';
import type { AnnotationWithContainer, Annotation, AnnotationContainer } from '@/types/question.types.ts';
import HighlightableBlocks from './HighlightableBlocks';
import SAVE_ANNOTATION_MUTATION from '@/graphql/mutations/save-annotation.graphql';
import DELETE_ANNOTATIONS_MUTATION from '@/graphql/mutations/delete-annotations.graphql';
import ANSWER_QUESTION_MUTATION from '@/graphql/mutations/answer-question.graphql';
import CLIENT_EXAM_COMPONENT_QUERY from '@/graphql/queries/client-exam-component.graphql';
import notify from '@/utils/notifications.tsx';
import { useParams } from 'react-router-dom';

export const ContentWithAnnotations = ({
  examQuestionId,
  clientExamQuestionId,
  annotations,
  container,
  data
}: ContentProps) => {
  const saveAnnotation = useSaveAnnotationCallback(examQuestionId, container, clientExamQuestionId);
  const deleteAnnotation = useDeleteAnnotationCallback();

  return (
    <HighlightableBlocks
      // Reset component state when question changes
      key={examQuestionId}
      data={data}
      initialAnnotations={annotations}
      saveAnnotation={saveAnnotation}
      deleteAnnotation={deleteAnnotation}
    />
  );
};

const useSaveAnnotationCallback = (
  examQuestionId: number, container: AnnotationContainer, clientExamQuestionId?: number
) => {
  const params = useParams();
  const [answerQuestion] = useMutation<SaveAnswerResponse>(ANSWER_QUESTION_MUTATION, errorHandler);
  const [saveAnnotation] = useMutation<SaveAnnotationResponse, SaveAnnotationVariables>(
    SAVE_ANNOTATION_MUTATION, errorHandler
  );

  return useCallback(async (annotation: Annotation) => {
    if (!clientExamQuestionId) {
      // We need to create a blank answer, in order to save annotations as they use the clientExamQuestion type
      await answerQuestion({
        variables: { input: { examQuestionId } }
      });
    }

    const result = await saveAnnotation({
      variables: {
        annotationId: annotation.clientExamQuestionAnnotationId || undefined,
        questionId: examQuestionId,
        offset: annotation.offset,
        selection: annotation.selection,
        highlightColor: annotation.highlightColor,
        container,
        comment: annotation.comment
      },
      refetchQueries: [{
        query: CLIENT_EXAM_COMPONENT_QUERY,
        variables: { examComponentId: Number.parseInt(params.examComponentId ?? '') }
      }],
    });

    if (result.errors) {
      throw new Error(result.errors.map(e => e.message).join('\n\n'));
    }

    const clientExamQuestionAnnotationId =
      result.data?.questionAnnotation.clientExamQuestionAnnotationId || annotation.clientExamQuestionAnnotationId;

    return {
      ...annotation,
      clientExamQuestionAnnotationId,
    };
  }, [answerQuestion, clientExamQuestionId, container, examQuestionId, params.examComponentId, saveAnnotation]);
};

const useDeleteAnnotationCallback = () => {
  const params = useParams();
  const [execute] = useMutation<DeleteAnnotationsResponse, DeleteAnnotationsVariables>(
    DELETE_ANNOTATIONS_MUTATION, errorHandler
  );

  return useCallback(async (id: number) => {
    await execute({
      variables: { ids: [id] },
      refetchQueries: [{
        query: CLIENT_EXAM_COMPONENT_QUERY,
        variables: { examComponentId: Number.parseInt(params.examComponentId ?? '') }
      }],
    });
  }, [execute, params.examComponentId]);
};

const errorHandler = {
  onError: (e: ApolloError) => {
    notify(
      'Saving annotation failed',
      e.message,
      {
        type: 'error'
      }
    );
  }
};

interface SaveAnswerResponse {
  answerQuestion: {
    clientExamComponentId: number,
    clientExamQuestionId: number
  }
}

interface SaveAnnotationVariables {
  questionId: number;
  offset: number;
  selection: string;
  highlightColor: number;
  annotationId?: number;
  comment?: string;
  container: AnnotationContainer;
}

interface SaveAnnotationResponse {
  questionAnnotation: {
    clientExamQuestionAnnotationId: number;
    clientExamQuestionId: number;
  };
}

interface DeleteAnnotationsVariables {
  ids: number[];
}

interface DeleteAnnotationsResponse {
  questionAnnotationDelete: boolean;
}

export interface ContentProps {
  examQuestionId: number;
  clientExamQuestionId?: number;
  data: DataProp;
  container: AnnotationContainer;
  annotations?: AnnotationWithContainer[];
  config?: ConfigProp;
}
