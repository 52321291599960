import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Popover from './Popover';
import Button from './Button';
import Separator from './Separator';
import QuestionButton, { IVariant } from './QuestionButton';
import { TypoCallout, TypoFootnote } from './Typography';
import { IQuestion } from '@/types/question.types.ts';

interface QuestionsPopoverProps {
  isOpen: boolean;
  isPreview: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selected: number;
  title: string;
  onQuestionSelect: (questionNumber: number) => void;
  onCompleteExam: () => void;
  list: IQuestion[];
  isLastQuestion?: boolean;
}

const QuestionsPopover = ({
  isPreview,
  isOpen,
  setOpen,
  list,
  selected,
  title,
  onQuestionSelect,
  onCompleteExam,
  isLastQuestion
}: QuestionsPopoverProps ) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>();

  const showCompleteOption = isLastQuestion && !isPreview;

  const togglePopover = useCallback(() => setOpen(c => !c), [setOpen]);

  const handleQuestionSelect = useCallback((questionNumber: number) =>{
    onQuestionSelect(questionNumber);
    if (showCompleteOption) setOpen(false);
  }, [onQuestionSelect, showCompleteOption, setOpen]);

  return (
    <>
      <Button ref={setAnchor} withArrow onClick={togglePopover}>Questions {selected} of {list.length}</Button>

      <Popover
        placement="top"
        title={title}
        heading={showCompleteOption ? 'Review Your Work' : undefined}
        open={isOpen}
        anchor={anchor}
        onClose={() => setOpen(false)}
      >
        <div className="flex flex-col justify-center gap-4">
          <div className="flex justify-center items-center gap-4 pt-6">
            <LegendItem variant="unanswered" title="Unanswered" />
            <LegendItem variant="answered" title="Answered" />
            <LegendItem variant="review" title="For Review" />
            <LegendItem variant="current" title="Current" />
          </div>

          <Separator />

          <div className="flex justify-center flex-wrap gap-1.5 pt-6 pb-7 px-16">
            {
              list.map((item, idx) =>
                <QuestionButton
                  key={idx}
                  number={idx + 1}
                  variant={getVariant({ item, idx, selected })}
                  onClick={handleQuestionSelect}
                  isMarked={Boolean(item.isMarked)}
                />
              )
            }
          </div>

          {
            showCompleteOption
              ? (
                <div className="text-center">
                  <Button onClick={onCompleteExam}>Complete Module</Button>
                  <TypoFootnote className="block text-neutral2-text mt-4">
                    If you finish a module with unanswered questions, you won’t be able to revisit it.
                  </TypoFootnote>
                </div>
              )
              : null
          }
        </div>
      </Popover>
    </>
  );
};

interface getVariantProps {
  item: IQuestion
  idx: number;
  selected: number;
}

const getVariant = ({ item, idx, selected }: getVariantProps): IVariant['variant'] => {
  if (idx + 1 === selected) return 'current';
  if (item.examAnswerId || item.answerInput?.length) return 'answered';
  if (item.isMarked) return 'review';

  return 'unanswered';
};

interface LegendItemProps extends IVariant {
  title: string;
}

const LegendItem = ({ variant, title }: LegendItemProps) => (
  <div className="flex gap-2 items-center">
    <QuestionButton legend variant={variant} />
    <TypoCallout className="text-neutral2-text-softer">{title}</TypoCallout>
  </div>
);

export default QuestionsPopover;
