export const COLOR_OPTIONS = {
  1: {
    name: 'Yellow',
    hex: '#FFC857',
    className: 'bg-warning text-warning-text hover:bg-warning-hover hover:text-warning-hover-text',
    selection: 'selection:bg-warning-hover selection:text-warning-text',
    id: 1,
  },
  2: {
    name: 'Pink',
    hex: '#F2C4CB',
    className: 'bg-primary-icon text-neutral-decoration hover:bg-secondary hover:text-neutral-decoration',
    selection: 'selection:bg-secondary selection:text-neutral-decoration',
    id: 2,
  },
  3: {
    name: 'Green',
    hex: '#5AA05E',
    className: 'bg-success text-success-text hover:bg-success-hover hover:text-success-hover-text',
    selection: 'selection:bg-success-hover selection:text-success-text',
    id: 3,
  },
  4: {
    name: 'Blue',
    hex: '#058ED9',
    className: 'bg-info text-info-text hover:bg-info-hover hover:text-info-hover-text',
    selection: 'selection:bg-info-hover selection:text-info-text',
    id: 4,
  },
  5: {
    name: 'Orange',
    hex: '#FF6B35',
    className: 'bg-[#FF6B35] text-danger-text hover:bg-[#FF8C69] hover:text-danger-hover-text',
    selection: 'selection:bg-[#FF8C69] selection:text-danger-text',
    id: 5,
  }
};