import { ReactNode, useState } from 'react';
import { DataProp } from 'editorjs-blocks-react-renderer';
import Tabs from '@/components/Tabs';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import { ExamType } from '@/types/common.types.ts';
import { TypoBody } from '@/components/Typography.tsx';
import {
  AnnotationsToggle,
  ContentProps,
  ContentWithAnnotations,
  useAnnotationsContext
} from '@/components/Annotations';
import { AnnotationWithContainer } from '@/types/question.types.ts';

interface QuestionProps {
  examQuestionId: number;
  clientExamQuestionId?: number;
  question: DataProp;
  explanation: DataProp;
  directions?: DataProp;
  componentType: ExamType;
  annotations?: AnnotationWithContainer[],
  categories?: { name: string }[];
  readOnly?: boolean;
}

const QuestionSection = ({
  examQuestionId,
  clientExamQuestionId,
  question, directions,
  explanation,
  componentType,
  annotations,
  categories,
  readOnly
}: QuestionProps) => {
  const [annotationsDisabled, setAnnotationsDisabled] = useState(false);
  let annotationsToggle: ReactNode = <AnnotationsToggle disabled={annotationsDisabled} count={annotations?.length} />;
  let tabs = [
    {
      title: 'Question',
      content: (
        <QuestionContent
          examQuestionId={examQuestionId}
          clientExamQuestionId={clientExamQuestionId}
          data={question}
          annotations={annotations?.filter(a => a.container === 'QUESTION')}
          container="QUESTION"
        />
      ),
    },
    {
      title: 'Directions',
      content: directions?.blocks?.length
        ? <BlocksRenderer data={directions} />
        : <TypoBody className="whitespace-pre-wrap">{getDirectionText(componentType)}</TypoBody>,
    },
  ];

  if (readOnly) {
    annotationsToggle = null;

    if (explanation?.blocks?.length) {
      tabs = tabs.concat({
        title: 'Explanation',
        content: <ExplanationContent categories={readOnly ? categories : []} data={explanation} />
      });
    }
  }

  if (componentType == 'MATH') {
    annotationsToggle = null;
  }

  return (
    <section className="flex-1 flex flex-col gap-6 pb-10 px-16 overflow-auto">
      <Tabs
        barSlot={annotationsToggle}
        barStyle="sticky top-0 bg-neutral pt-10"
        containerStyle="p-11 m-0 text-neutral-text"
        onChange={(index) => setAnnotationsDisabled(index === 1)}
        tabs={tabs}
      />
    </section>
  );
};

interface ExplanationContentProps {
  data: QuestionProps['explanation'];
  categories?: QuestionProps['categories'];
}

const ExplanationContent = ({ data, categories = [] }: ExplanationContentProps) => {
  const hasCategories = categories?.length > 0;

  if (hasCategories) {
    const [first, ...rest] = categories;

    return (
      <>
        <div className="mb-4">
          <strong>Categories: </strong>
          <span>
            {first.name}
            {rest.map(c => `, ${c.name}`)}
          </span>
        </div>
        <div>
          <strong>Explanation:</strong>
          <BlocksRenderer data={data}/>
        </div>
      </>
    );
  }

  return <BlocksRenderer data={data} />;
};

const QuestionContent = ({ data, ...rest }: ContentProps) => {
  const [annotationsEnabled] = useAnnotationsContext();

  if (!data.blocks?.length) {
    return 'No valid question';
  }

  if (annotationsEnabled) {
    return <ContentWithAnnotations data={data} {...rest} />;
  }

  return <BlocksRenderer data={data} />;
};

const getDirectionText = (type: ExamType) => {
  if (type === 'RW') {
    return `The questions in the Reading and Writing section address several important reading and writing skills.
Each question contains one or more passages, and those passages might include a table or graph. Read
each passage and question carefully, and then choose the best answer to the question based on the
passage(s).
All of the questions in the Reading and Writing modules are multiple-choice with four answer choices
and each question has only one correct answer.
    `;
  }

  if (type === 'MATH') {
    return `The questions in this section will test you on a wide variety of important math skills.
You are allowed to use a calculator and the reference sheet for all questions unless otherwise indicated.`;
  }

  return 'No directions';
};

export default QuestionSection;
