import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, Navigate, useParams } from 'react-router-dom';
import { HistoryLoader } from '@/components/Loader.tsx';
import { TypoHeading2 } from '@/components/Typography.tsx';
import { ArrowLeft2 } from '@/components/Icons.tsx';
import TestList, { TestListProps } from '@/components/TestList.tsx';
import EXAM_SUBGROUP_QUERY from '@/graphql/queries/exam-subgroup.graphql';
import CLIENT_EXAMS_QUERY from '@/graphql/queries/client-exams-short.graphql';

const ExamSubGroup = () => {
  const { groupId = '', subGroupId = '' } = useParams();
  const { loading, name, list } = useExamSubGroup(Number(subGroupId));

  if (!groupId) return <Navigate to="/dashboard" replace />;
  if (!subGroupId) return <Navigate to={`/practice-packs/${groupId}`} replace />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Practice Packs
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {loading ? (
          <>
            <HistoryLoader />
            <HistoryLoader />
            <HistoryLoader />
          </>
        ) : (
          <>
            <div className="flex items-center gap-x-3 py-2 mb-8">
              <Link
                to={`/practice-packs/${groupId}`}
                className="flex flex-row items-center rounded px-4 py-2 gap-x-2 bg-shade-5 whitespace-nowrap"
              >
                <ArrowLeft2 width={16} height={16} /><span className="text-common-white text-callout">Back</span>
              </Link>
              <strong className="font-primary text-body py-2">{name}</strong>
            </div>
            <TestList loading={loading} list={list} />
          </>
        )}
      </div>
    </>
  );
};

const useExamSubGroup = (id: number) => {
  const { data, loading: subGroupLoading } = useQuery<QueryResult>(EXAM_SUBGROUP_QUERY, {
    variables: { groupId: id }
  });

  const { data: clientExamsData, loading: clientExamsLoading } = useQuery(CLIENT_EXAMS_QUERY);

  const clientExams = useMemo(() => clientExamsData?.clientExams as any[] ?? [], [clientExamsData?.clientExams]);
  const exams: TestListProps['list'] = useMemo(() =>
    (data?.examSubgroup.exams ?? []).map((exam: ExamProps) => {
      const taken = clientExams.find(e => e.exam.examId === exam.examId);

      return {
        id: exam.examId,
        title: exam.name,
        subtitle: exam.examType.name,
        isActive: taken && !taken.isCompleted,
        isCompleted: taken?.isCompleted,
      };
    }), [data?.examSubgroup.exams, clientExams]);

  const uniqueExams = exams.reduce((acc, current) => {
    if (!acc.find((exam) => exam.id === current.id)) {
      acc.push(current);
    }

    return acc;
  }, [] as TestListProps['list']);

  return { name: data?.examSubgroup?.name || '', list: uniqueExams, loading: subGroupLoading || clientExamsLoading };
};

interface ExamProps {
  examId: number;
  name: string;
  examType: { name: string; }
}

interface QueryResult {
  examSubgroup: {
    examSubgroupId: number;
    name: string;
    exams: ExamProps[];
  };
}

export default ExamSubGroup;
