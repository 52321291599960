import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, Navigate, useParams } from 'react-router-dom';
import { CollectionProps, CollectionItem, CollectionItemProps } from '@/components/CollectionsList.tsx';
import { HistoryLoader } from '@/components/Loader.tsx';
import { TypoHeading2 } from '@/components/Typography.tsx';
import { ArrowLeft2 } from '@/components/Icons.tsx';
import EXAM_GROUP_QUERY from '@/graphql/queries/exam-group.graphql';

const ExamGroup = () => {
  const { groupId = '' } = useParams();
  const { loading, ...collection } = useExamGroup(Number(groupId));

  if (!groupId) return <Navigate to="/dashboard" replace />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Practice Packs
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {loading ? (
          <>
            <HistoryLoader />
            <HistoryLoader />
            <HistoryLoader />
          </>
        ) : <Content {...collection} />}
      </div>
    </>
  );
};

const Content = ({ name, items }: CollectionProps) => (
  <>
    <div className="flex items-center gap-x-3 py-2 mb-8">
      <Link
        to="/dashboard"
        className="flex flex-row items-center rounded px-4 py-2 gap-x-2 bg-shade-5 whitespace-nowrap"
      >
        <ArrowLeft2 width={16} height={16} /><span className="text-common-white text-callout">Back</span>
      </Link>
      <strong className="font-primary text-body py-2">{name}</strong>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-6">
      {
        items?.map((item) =>
          <CollectionItem key={item.id} className="w-full" {...item} />
        )
      }
    </div>
  </>
);

const useExamGroup = (id: number): CollectionProps & { loading: boolean } => {
  const { data, loading } = useQuery<QueryResult>(EXAM_GROUP_QUERY, {
    variables: { groupId: id }
  });

  return useMemo(() => {
    const name = data?.examGroup.name || '';
    const items: CollectionItemProps[] = (data?.examGroup.subtypes ?? []).map(subGroup => ({
      id: subGroup.examSubgroupId,
      name: subGroup.name,
      type: 'collection',
      icon: subGroup.icon,
      to: `/practice-packs/${data?.examGroup.examGroupId}/${subGroup.examSubgroupId}`,
    }));

    return { id, name, items, loading };
  }, [data?.examGroup, id, loading]);
};

interface QueryResult {
  examGroup: {
    examGroupId: number;
    name: string;
    subtypes: {
      examSubgroupId: number;
      name: string;
      icon: string;
    }[];
  };
}

export default ExamGroup;
