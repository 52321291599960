import { DataProp } from 'editorjs-blocks-react-renderer';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import { TypoBody } from '@/components/Typography.tsx';
import { ContentProps, ContentWithAnnotations, useAnnotationsContext } from '@/components/Annotations';

interface AnswerDirectionsWithAnnotationsProps extends ContentProps {
  isSelectType?: boolean;
}

export const AnswerDirectionsWithAnnotations =
  ({ data, isSelectType, ...rest }: AnswerDirectionsWithAnnotationsProps) => {
    const [annotationsEnabled] = useAnnotationsContext();

    if (data?.blocks?.length) {
      if (annotationsEnabled) {
        return <ContentWithAnnotations data={data} config={BLOCK_CONFIG} {...rest} />;
      }

      return <BlocksRenderer data={data} config={BLOCK_CONFIG} />;
    }

    return (
      <TypoBody className="font-medium mt-2 text-neutral-text">
        {/*eslint-disable max-len*/}
        {
          isSelectType
            ? 'Select your answer choice below. Optionally, mark it for review or use strike-through to eliminate options.'
            : ''
        }
      </TypoBody>
    );
  };

const AnswerDirections = ({ directions, isSelectType }: { directions: DataProp; isSelectType?: boolean; }) => {
  if (directions?.blocks?.length) {
    return <BlocksRenderer data={directions} config={BLOCK_CONFIG} />;
  }

  return (
    <TypoBody className="font-medium mt-2 text-neutral-text">
      {/*eslint-disable max-len*/}
      {
        isSelectType
          ? 'Select your answer choice below. Optionally, mark it for review or use strike-through to eliminate options.'
          : ''
      }
    </TypoBody>
  );
};

const BLOCK_CONFIG = {
  paragraph: {
    className: 'font-primary text-body mt-2 text-body font-medium text-neutral-text',
    actionsClassNames: {
      alignment: 'text-{alignment}',
    }
  },
};

export default AnswerDirections;
