import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { DataProp } from 'editorjs-blocks-react-renderer';
import { Dollar, PSATIllustration, SATIllustration, TreasureChestIllustration } from './Icons';
import { TypoBody, TypoCallout, TypoFootnote, TypoHeading } from './Typography';
import { TestItemLoader } from './Loader';
import Button from './Button';
import MissingTests from './MissingTests';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import useCheckoutAction from '@/hooks/useCheckoutAction.ts';

const TestList = ({ list, loading, withPrice, hideButton = false }: TestListProps) => {
  if (loading) return <TestItemLoader withPrice={withPrice} />;

  if (!list.length) return <MissingTests text="No tests available." />;

  return (
    <div className="flex flex-col gap-1">
      {
        list.map((test) =>
          <TestItem key={test.id} {...test} withPrice={withPrice} hideButton={hideButton} />
        )
      }
    </div>
  );
};

const TestItem = ({
  id,
  title,
  subtitle,
  description,
  price,
  priceId,
  isActive,
  isCompleted,
  withPrice = false,
  hideButton = false
}: TestItemProps) => {
  const Illustration = getIllustration(subtitle);
  const hasDescription = description && description?.blocks?.length > 0;
  const styles = `${hasDescription ? 'items-start py-4' : 'items-center py-2'}`;

  return (
    <div className={`flex justify-between gap-6 px-4 rounded-xl bg-neutral ${styles}`}>
      <Illustration width="72" height="72" />
      <div className="flex-1">
        <TypoBody className="text-neutral-text-strong font-semibold">{title}</TypoBody>
        <TypoCallout className="text-neutral-text-soft font-medium">{subtitle}</TypoCallout>
        {
          hasDescription &&
          <div className="text-neutral-text-softer">
            <BlocksRenderer
              data={description}
              config={{
                paragraph: {
                  className: 'text-footnote font-medium mt-1',
                  actionsClassNames: {
                    alignment: 'text-{alignment}',
                  }
                },
              }}
            />
          </div>
        }
      </div>
      {
        withPrice
          ? (
            <div className="flex items-center gap-2">
              <TestPrice price={price} />
              {!hideButton ? <BuyButton price={price} id={priceId} /> : null}
            </div>
          )
          : (
            <Link to={`/tests/${id}`}>
              <Button>{isActive ? 'Resume' : isCompleted ? 'Start Again' : 'Start Test'}</Button>
            </Link>
          )
      }
    </div>
  );
};

const BuyButton = ({ price = 0, id = 0 }: { price?: number; id?: number }) => {
  const { checkout, loading } = useCheckoutAction(id);
  const onPurchase = useCallback(async () => await checkout(), [checkout]);

  return (
    <Button withArrow color="success" onClick={onPurchase} disabled={loading}>
      {price === 0 ? 'Get' : 'Buy'}
    </Button>
  );
};

const TestPrice = ({ price = 0 }: { price?: number }) => {
  const [whole, decimal] = getPrice(price);

  if (price === 0) return <TypoFootnote variant="secondary" className="text-success font-bold">FREE</TypoFootnote>;

  return (
    <div className="flex items-start text-success">
      <Dollar width="12" height="12" />
      <TypoHeading variant="secondary" className="font-bold leading-[0.6875rem]">
        {whole}
      </TypoHeading>
      <TypoFootnote variant="secondary" className="leading-[0.375rem]">
        {decimal}
      </TypoFootnote>
    </div>
  );
};

const getPrice = (price: number) =>
  (Math.round(price * 100) / 100).toFixed(2).split('.');

const getIllustration = (type: string) => {
  switch (type.toUpperCase()) {
  case 'PSAT':
    return PSATIllustration;
  case 'SAT':
  case 'SET':
    return SATIllustration;
  default:
    return TreasureChestIllustration;
  }
};

interface TestItemProps {
  id: number;
  title: string;
  subtitle: string;
  description?: DataProp;
  price?: number;
  priceId?: number;
  withPrice?: boolean;
  hideButton?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
}

export interface TestListProps {
  loading: boolean;
  withPrice?: boolean;
  hideButton?: boolean;
  list: Array<TestItemProps>
}

export default TestList;
